import React from "react"
import { Link } from "gatsby"
import { Alert } from "react-bootstrap"

import Seo from "../components/seo"

const SponsorCanceledPage = () => (
  <>
    <Seo title="Canceled" />
    <Alert variant='warning'>
      The transaction was canceled.
    </Alert>
    <Link to="/sponsorship">Go back to the Sponsorship Page</Link>
  </>
)

export default SponsorCanceledPage